import React, { useState, useEffect } from 'react';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from 'js-cookie';

import "./ProfileButton.css";


const API_URL = process.env.REACT_APP_API_URL;

const ProfileButton = () => {
  const [user, setUser] = useState(null); // Store user info
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profilePic, setProfilePic] = useState('');

  // Handle checking if a stored token exists and setting user details
  useEffect(() => {
    const storedToken = Cookies.get('google_token');

    if (storedToken) {
      // If token exists in cookies, use it to fetch user details
      const userObject = JSON.parse(atob(storedToken.split('.')[1]));
      setUser(userObject);
      setIsLoggedIn(true);
    }
  }, []); // Run only once on mount

  // Handle updating the profile pic when the user object changes
  useEffect(() => {
    if (user) {
      setProfilePic(user.picture);
    }
  }, [user]); // Only runs when 'user' changes

 
  const handleLoginSuccess = (response) => {
    // Send the Google token to the backend (Flask server) for validation
    const { credential } = response;


    // Save the Google token in cookies (1 hour expiration time)
    Cookies.set('google_token', credential, { expires: 1 / 24 }); // Expires in 1 hour

    // Decode the credential to get user info (you might use JWT decoding here)
    const userObject = JSON.parse(atob(credential.split('.')[1])); 
    setUser(userObject);
    setIsLoggedIn(true);

    // Send token to backend to authenticate. 
    fetch(`${API_URL}/auth/google`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({ token: credential }),
    })
      .then((res) => res.json())
      .then((data) => {
        // Handle server response (e.g., save session, redirect user)
        console.log(data);
      })
      .catch((err) => console.error('Error:', err));
  };

  const handleLogout = () => {
    googleLogout();  // Logs the user out from Google

    // Call backend to log out
    fetch(`${API_URL}/auth/logout`, {
      method: 'POST',
      credentials: 'include'  // Ensure the session cookie is sent
    })
    .then((res) => res.json())
    .then((data) => {
      console.log('Logout response from backend:', data);
    })
    .catch((err) => console.error('Error logging out:', err));
    
    setUser(null);
    setIsLoggedIn(false);

    // Remove the token from cookies when logging out
    Cookies.remove('google_token');
  };

  return (
    <div className="profile">
      {isLoggedIn ? (
        <div>
          <img className="profile-pic" src={user.picture} alt="Profile" />
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <GoogleOAuthProvider clientId="189951337231-qrdqv4gnt1lngbeitttsiuo8hk0cqr6e.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => console.log('Login Failed')}
          />
        </GoogleOAuthProvider>

      )}
    </div>
  );
};

export default ProfileButton;