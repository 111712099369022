import React, { useState, useEffect } from "react";
import ProfileButton from "./ProfileButton";
import ChatBox from "./ChatBox.js";
import History from "./History.js";
import ChatApp from "./ChatApp.js";
import "./App.css"; // Convert styles.css into React-friendly CSS

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  const [pastQueries, setPastQueries] = useState([]);

  // useEffect(() => {
  //   // Fetch past queries from Flask API on load
  //   console.log(`${API_URL}/api/past-queries`);
  //   fetch(`${API_URL}/api/past-queries`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     credentials: "include",
  //   })
  //     .then((response) => {response.json(); console.log(response) })
  //     .then((data) => {
  //       console.log(data)
  //       setPastQueries(data.queries)

  //       }
  //       )
  //     .catch((error) => console.error("Error fetching past queries:", error));
  // }, []);

  return (
    <div className="App">
      <ProfileButton />
      <ChatApp />
      {/* <History queries={pastQueries} /> */}
    </div>
  );
}

export default App;