import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for loading spinner

import { Table } from "antd"
;
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./ChatApp.css";

const API_URL = process.env.REACT_APP_API_URL;


const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSend = async (query) => {
    if (!query.trim()) return;

    // Add user's message to chat
    setMessages((prev) => [...prev, { sender: "user", content: query, type: "text" }]);
    setLoading(true);

    // Currently we only get messages that have content field populated, so we're not passing the data table back.
    const messagesHistory = 
      messages
      .filter((msg) => msg.content)
      .map((msg) => ({
        role: msg.sender,
        content: msg.content,
      }));

    // Send to Flask backend
    console.log(`${API_URL}/api/query`);
    console.log(query);
    fetch(`${API_URL}/api/query`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ query: query, messages: messagesHistory}),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data && Array.isArray(data.table) && data.table.length !== 0) {
          console.log(data.table)
          // Assuming data is an array of records (rows)
          const keys = Object.keys(data.table[0]); // Extract column names from the first row

          const columns = keys.map((key) => ({
            title: key.toUpperCase(),
            dataIndex: key,
            key: key,
            sorter: (a, b) => {
              // Handle numbers
              if (typeof a[key] === "number" && typeof b[key] === "number") {
                return a[key] - b[key];
              }
          
              // Handle strings (case-insensitive comparison)
              if (typeof a[key] === "string" && typeof b[key] === "string") {
                return a[key].localeCompare(b[key]);
              }
          
              // Default case: no sorting
              return 0;
            },
            sortDirections: ["ascend", "descend"], // Optional: Enable both ascending and descending sorts
            fixed: key === 'Week' ? 'left' : undefined, // Fix the 'Week' column to the left
            className: key === 'Week' ? 'fixed-column-left' : undefined,
            width: key === 'Week' ? 125 : undefined,
            // Add filters dynamically based on unique values
            filters: generateFiltersForColumn(key),
            onFilter: (value, record) => record[key] === value,
          }));

          const rows = data.table.map((row, index) => ({ id: index, ...row }));

          
          // Helper function to generate unique filters for a column
          function generateFiltersForColumn(columnKey) {
            // Use your data source to extract unique values for each column
            const uniqueValues = [...new Set(data.table.map((item) => item[columnKey]))];
          
            // Create filter options
            return uniqueValues.map((value) => ({
              text: value.toString(), // Display value in the filter dropdown
              value: value, // The actual filter value
            }));
          }

          // Add server's response
          setMessages((prev) => [...prev, { sender: "assistant", content: data.summary, type: "text" }]);
          setMessages((prev) => [...prev, { sender: "assistant", rows: rows, columns: columns, type: "grid" }]);
        } else {
          // no data was returned in this case
          setMessages((prev) => [...prev, { sender: "assistant", content: "No data returned by query", type: "no_data" }]);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="chat-app">
      <h1>Ask Cover Boy</h1>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {messages.map((msg, index) => {
               if (msg.type === "text") {
                  return <Message
                    key={index}
                    model={{
                      message: msg.content,
                      sentTime: "just now",
                      sender: msg.sender === "user" ? "You" : "Bot",
                      direction: msg.sender === "user" ? "outgoing" : "incoming",
                      position: "single",
                    }}
                  />
              } else if (msg.type === "grid") {
                return <Message
                  className="grid-message"
                  key={index}
                  model={{
                    type: "custom",
                    sentTime: "just now",
                    sender: "Bot",
                    direction: "incoming",
                    position: "single"
                  }}
                >
                <Message.CustomContent>
                  <div className="data-grid">
                    <Table
                      className="ant-table-wrapper"
                      columns={msg.columns}
                      dataSource={msg.rows}
                    />
                  </div>
                  </Message.CustomContent>
                </Message>
                
              } else if (msg.type === "no_data") {
                return <Message
                    key={index}
                    model={{
                      message: msg.content,
                      sentTime: "just now",
                      sender: msg.sender === "user" ? "You" : "Bot",
                      direction: msg.sender === "user" ? "outgoing" : "incoming",
                      position: "single",
                    }}
                  />
              } else if (msg.type === "error") {
                return (
                  <div key={index} className="error-message">
                    <p>An error occurred. Please try again.</p>
                  </div>
                );
              } else {
                return null;
              }
            })}
            {loading && (
              <div className="loading-spinner">
                <CircularProgress />
              </div>
            )}
          </MessageList>
          <MessageInput
            placeholder="Type your message..."
            onSend={handleSend}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};


export default ChatApp;